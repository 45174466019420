import React, { useRef, useState, useEffect } from 'react'
import { styled } from 'styled-components'
import { Form, Select, Typography, Descriptions, Button, Input, InputNumber, DatePicker, FloatButton } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';
import ReactToPrint from "react-to-print";
import RecordResultEdit from '../../components/Record/RecordResultEdit';
import Lineup from '../../components/Record/Lineup';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import useReadMatch from '../../hooks/Match/useReadMatch';
import useGetLineup from '../../hooks/Record/useGetLineup';
import useGetRecord from '../../hooks/Record/useGetRecord';
import useCreateRecord from '../../hooks/Record/useCreateRecord';
import useDeleteRecord from '../../hooks/Record/useDeleteRecord';
import UpdateModal from '../../components/commons/UpdateModal';
import DeleteModal from '../../components/commons/DeleteModal'
import Notification from '../../components/commons/Notification';
import StyleAtoms from '../../common/StyleAtoms';
import images from '../../common/images';
import Navigation from '../../common/Navigation';
import { 대회종류번역기 } from '../../common/util'
import { inputFieldNames, inputFieldExtraNames, inputFieldScoreNames } from '../../common/Data'
import { useAppContext } from '../../contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { formatStopWatchTime } from '../../common/util';

const RecordEdit = () => {
    const {PageTitle, BlueImg, TitleBox, Title, SubTitle, SSearch, InvertImg, CompTitle, RedDot } = StyleAtoms();
    const { Text } = Typography;
    const { compId, matchId } = useParams();
    const navigate = useNavigate(); 
    const location = useLocation();
    const [printContent, setPrintContent] = useState("");
    const ref = useRef();
    const [form] = Form.useForm();
    const { compName } = queryString.parse(location.search);
    const { matchContent } = useReadMatch();
    const { lineupContent, getLineup } = useGetLineup();
    const { recordContent, getRecord } = useGetRecord();
    const { recordResult, createRecord, setRecordResult } = useCreateRecord();
    const { deleteRecordResult, deleteRecord, setDeleteRecordResult } = useDeleteRecord()
    const [createId, setCreateId] = useState();
    const { contextHolder, successNoti, errorNoti, loadingNoti, closeLoadingNoti, goalCheckNoti, 
      recordCheckNoti, recordValidateCheck } = Notification();
    const [filteredLineup, setFilteredLineup] = useState();
    const {goComp, goCompList, goMatchEdit, goRecordRead} = Navigation();
    const [loadingStatus, setLoadingStatus] = useState();
    const [compareGoalHome, setCompareGoalHome] = useState();
    const [compareGoalAway, setCompareGoalAway] = useState();
    const [compareScoreHome, setCompareScoreHome] = useState();
    const [compareScoreAway, setCompareScoreAway] = useState();
    const [forfeitureSwitch, setForfeitureSwitch] = useState(false);
    const { state, dispatch } = useAppContext();
    const { collapsed, elapsedTime } = state;
    const seperatedField = ['endAt', 'extraSwitch'];
    const totalFieldNames = [...inputFieldNames, ...inputFieldExtraNames, ...inputFieldScoreNames, ...seperatedField];


    const handleToggleCollapsed = () => {
      dispatch({type: 'TOGGLE_COLLAPSE'});
    }

    const scoreAlertNoti = () => {
      if(compareGoalHome !== compareScoreHome || compareGoalAway !== compareScoreAway) {
        goalCheckNoti();
      }
    }

    const onCreateSubmit = async () => {
      window.scrollTo(0, 0)
      form.validateFields().then(()=>{
        if(forfeitureSwitch === false && filteredLineup?.length <= 0) {
          //QR코드 아무도 안찍었을때
          recordCheckNoti();
        }
        else {
          loadingNoti()
          form.submit();
        }
      }).catch(error => {
        //폼에 필수값 안넣었을때
        window.scrollTo(0, 0)
        recordValidateCheck();
      })
    }

    const onDeleteSubmit = () => {
      window.scrollTo(0, 0)
      loadingNoti()
      deleteRecord();
    }

    const saveToSessionStorage = (matchId, name) => (e) => {
      if(!recordContent) {
        const {value} = e.target;
        sessionStorage.setItem(matchId + name, value)  
      }
    }

    const saveDateToSessionStorage = (matchId, name, date) => {
      if(!recordContent) {
        const timestamp = dayjs(date?.valueOf())
        sessionStorage.setItem(matchId + name, timestamp)
      }
    }

    useEffect(() => {
      window.scrollTo(0, 0)
      getRecord();
    }, []);

    useEffect(()=>{
      if(loadingStatus===true){
        loadingNoti();
      }
    },[loadingStatus])

    useEffect(()=>{
      if (recordResult === false) {
        closeLoadingNoti();
        errorNoti();
      } else if (recordResult === true) {
        closeLoadingNoti();
        successNoti('입력'); 

        totalFieldNames.forEach(a => {
          sessionStorage.removeItem(matchId + a);
        });

        setTimeout(() => {
          navigate(-1);
        }, 1000);
      }
      setRecordResult(null);
    }, [recordResult])

    useEffect(()=>{
      if (deleteRecordResult === false) {
        closeLoadingNoti();
        errorNoti();
        setDeleteRecordResult(null);
      } else if (deleteRecordResult === true) {
        closeLoadingNoti();
        navigate(`/actionResult?compId=${encodeURIComponent(compId)}?compName=${encodeURIComponent(compName)}`, { replace: true });
      }
    }, [deleteRecordResult])

    useEffect(() => {
      const filteredPlayer = lineupContent
        ? lineupContent.filter((a) => a.isParticipated === true)
        : null;
        setFilteredLineup(filteredPlayer);
    }, [lineupContent]);

    //create or update distinguish
    useEffect(()=>{
      if(!recordContent) {
        setCreateId(matchId)
      } else {
        setCreateId(null)
      }
    },[recordContent])


    const items = [
      {
        key: 'recordEditItems1',
        label: '대회명',
        children: compName,
        span:2,
      },
      {
        key: 'recordEditItems2',
        label: '구분 / 경기명',
        children: <Text>{대회종류번역기(matchContent?.matchType)} / {matchContent?.name}</Text>,
        span:2,
      },
      {
        key: 'recordEditItems3',
        label: '경기 장소',
        children: <Text>{matchContent?.location}</Text>,
        span:2,
      },
      {
        key: 'recordEditItems4',
        label: '경기 기록 번호',
        children: 
        <Form.Item name='recordNumber'>
          <Input
            placeholder='경기 번호를 입력해 주세요'
            style={{width:'100%'}} 
            onChange={saveToSessionStorage(matchId,'recordNumber')}
            maxLength={20}
          />
        </Form.Item>,
        span:2,
      },
      {
        key: 'recordEditItems5',
        label: '경기 시작 일시',
        children: <Text>{dayjs(matchContent?.startAt).format('YYYY.MM.DD(dd) HH:mm')}</Text>,
        span:2,
      },
      {
        key: 'recordEditItems6',
        label: <span>경기 종료 일시<RedDot/></span>,
        children:
        <Form.Item name='endAt' rules={[{ required: true, message:'종료 시간 입력 필요' }]}>
          <DatePicker showTime format='YYYY-MM-DD HH:mm' 
            locale={locale} style={{width:'100%'}} placeholder='종료 일시'
            onChange={(date, dateString)=>saveDateToSessionStorage(matchId, 'endAt', dateString)}
          />
        </Form.Item>,
        span:2,
      },
    ];

  return (
    <>
    {contextHolder}
    <Wrapper>
      <PageTitle>
          <InvertImg src={images.icon.trophy} alt='' />
          <span onClick={goComp} className='pointer'>대회</span>
          <img src={images.icon.arrow} alt='' />
          <span className='pointer' onClick={()=>{goCompList(compId, compName)}}>{compName}</span>
          <img src={images.icon.arrow} alt='' />
          <span className='pointer' onClick={()=>{goMatchEdit(compId, matchId, compName)}}>
            {matchContent?.homeTeam.name} VS {matchContent?.awayTeam.name} {matchContent?.name
            ? <span>&#40;{matchContent?.name}&#41;</span> 
            : ''}
          </span>

          {recordContent 
          ? 
          <>
            <img src={images.icon.arrow} alt='' />
            <span className='pointer' onClick={()=>{goRecordRead(compId, matchId, compName)}}>경기 기록지</span>
            <BlueImg src={images.icon.arrow} alt='' />
            <span className='blue'>경기 기록지 수정</span>
          </> 
          : 
          <>
          <BlueImg src={images.icon.arrow} alt='' />
          <span className='blue'>경기 기록지 입력</span>
          </>}
      </PageTitle>
      <TitleBox>
          {recordContent ? 
          <Title>경기 기록지 수정</Title> : 
          <>
          <Title>경기 기록지 입력</Title>
          {collapsed && elapsedTime > 0 ? 
          null :
          <Button style={{marginLeft:'auto',}} onClick={handleToggleCollapsed}>
            <FontAwesomeIcon icon={faStopwatch} style={{ marginRight:'6px' }}/> 스톱워치
          </Button>  
          }
          </>}
      </TitleBox>
      {collapsed && elapsedTime > 0 ?
      <FloatButton 
        shape="square" 
        style={{top:'50px', right:'40px', height:'64px', width:'206px'}} 
        description={<StopWatchTimeBox>{formatStopWatchTime(elapsedTime)}</StopWatchTimeBox>}
        onClick={handleToggleCollapsed}
      /> 
      : null
      }
      
      <PrintWrapper ref={ref} content={printContent}>
        <Form form={form} name='recordResultForm' >
          <Descriptions bordered items={items} style={{marginBottom:'16px'}}/>
        </Form>

        <RecordResultEdit 
          form={form} recordContent={recordContent} matchId={createId}
          home={matchContent?.homeTeam.name} away={matchContent?.awayTeam.name} 
          createRecord={createRecord} 
          setCompareGoalHome={setCompareGoalHome} setCompareGoalAway={setCompareGoalAway}
          forfeitureSwitch={forfeitureSwitch} setForfeitureSwitch={setForfeitureSwitch}
        />

        <Lineup 
          type={'edit'} 
          homeUniform={matchContent?.homeTeamUniform} awayUniform={matchContent?.awayTeamUniform}
          home={matchContent?.homeTeam.name} away={matchContent?.awayTeam.name}
          matchName={matchContent?.name} matchTime={matchContent?.startAt} matchLocation={matchContent?.location}
          form={form} matchId={createId} recordContent={recordContent?.record} createRecord={createRecord}
          setCompareScoreHome={setCompareScoreHome} setCompareScoreAway={setCompareScoreAway}
          setLoadingStatus={setLoadingStatus}
          lineupContent={lineupContent} getLineup={getLineup}
        />

        <ButtonContainer>
          {recordContent ? 
          <>
          <DeleteModal onOk={onDeleteSubmit} />
          <UpdateModal form={form} scoreAlertNoti={scoreAlertNoti} onCreateSubmit={onCreateSubmit}/>
          </>
          : <Button type="primary" htmlType='submit' onClick={onCreateSubmit}>입력하기</Button>}
        </ButtonContainer>
      </PrintWrapper>
    </Wrapper>
    </>
  )
}

export default RecordEdit

const Wrapper = styled.div`
.ant-descriptions-item-label {
  width: 197px;
}
.ant-float-btn-body {
  height: 64px;
}
`;

const PrintWrapper = styled.div`
`;

const ButtonContainer = styled.div`
display: flex;
justify-content: flex-end; 
margin-top: 64px;
margin-bottom: 26px;
gap: 20px;
`;

const StopWatchTimeBox = styled.span`
font-size: 30px;
font-family: 'Chakra Petch Regular';
`


