import React from 'react'
import { styled } from 'styled-components'
import { useState } from 'react';
import { useEffect } from 'react';
import RecordScoreEdit from './RecordScoreEdit.js';
import RecordScoreAwayEdit from './RecordScoreAwayEdit'
import RecordScore from './RecordScore.js';
import RecordScoreAway from './RecordScoreAway.js';
import Notification from '../commons/Notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, } from '@fortawesome/free-solid-svg-icons';
import { Button, Checkbox } from 'antd';
import useGetLineup from '../../hooks/Record/useGetLineup.js';
import PlayerDetailModal from '../Comp/Player/PlayerDetailModal.js';
import { adminTypeChecker } from '../../common/util.js';
import { useCookies } from 'react-cookie';
import { ACCESS_TOKEN } from '../../common/api.js';
import useUpdatePlayerQr from '../../hooks/Match/SuperUser/useUpdatePlayerQr.js';

const Lineup = (props) => {
    const { 
        type, home, away, homeUniform, awayUniform, 
        form, matchId, recordContent, createRecord, 
        setCompareScoreHome, setCompareScoreAway, setLoadingStatus, 
        matchName, matchTime, matchLocation, lineupContent, getLineup
    } = props;

    const{ updatePlayerQr } = useUpdatePlayerQr();
    const [homeStarting, setHomeStarting] = useState([]);
    const [awayStarting, setAwayStarting] = useState([]);
    const [homeSub, setHomeSub] = useState([]);
    const [awaySub, setAwaySub] = useState([]);
    const {contextHolder, refreshNoti, loadingNoti, closeLoadingNoti, successNoti, errorNoti } = Notification();
    const [open, setOpen] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState('');
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const adminType = adminTypeChecker(cookies);

    const onCheckBoxChange = async (values) => {
        loadingNoti();
        const updResult = await updatePlayerQr(values)
        if (updResult === 200) {
            closeLoadingNoti();
            successNoti('반영');
        } else {
            closeLoadingNoti();
            errorNoti();
        }
        getLineup();
    }

    const refresh = () => { 
        getLineup(); 
        refreshNoti();
    };

    const showModal = (player) => {
        setSelectedPlayer(player);
        setOpen(true);
    };
    
    useEffect(() => {
        const homePlayers = lineupContent
          ? lineupContent.filter((a) => a.isHomeTeam && (a.playerStatus === 'CAPTAIN' || a.playerStatus === 'STARTING'))
          : [];
        const homeSubPlayers = lineupContent
          ? lineupContent.filter((a) => a.isHomeTeam && a.playerStatus === 'BENCH')
          : [];
        const awayPlayers = lineupContent
          ? lineupContent.filter((a) => !a.isHomeTeam && (a.playerStatus === 'CAPTAIN' || a.playerStatus === 'STARTING'))
          : [];
        const awaySubPlayers = lineupContent
          ? lineupContent.filter((a) => !a.isHomeTeam && a.playerStatus === 'BENCH')
          : [];
        setHomeStarting(homePlayers);
        setHomeSub(homeSubPlayers);
        setAwayStarting(awayPlayers);
        setAwaySub(awaySubPlayers);
      }, [lineupContent]);

    const lineupSpitter = (players) => {
        return players
            .sort((a, b) => {
                const posOrder = { GK: 1, DF: 2, MF: 3, FW: 4 };
                const posCompare = posOrder[a.position] - posOrder[b.position];
                if (posCompare !== 0) {
                    return posCompare;
                }
                return a.backNumber - b.backNumber;
            })
            .map((player) => (
                <ColRow key={player.id}>
                    <ShortCol className='pos'>{player.position}</ShortCol>
                    <ShortCol>{player.backNumber}</ShortCol>
                    <LongCol>
                        <span onClick={() => showModal(player)}>
                            {player.playerStatus === 'CAPTAIN' 
                            ? <Captain>{player.member.name}&nbsp;&nbsp;<span>🅲</span></Captain> 
                            : player.member.name}
                        </span>
                    </LongCol>
                    <ShortCol color={player.isHomeTeam}>
                        <QrStatusWrapper>
                            {player.isParticipated ? 'O' : ''}
                        </QrStatusWrapper>
                        {/* 🧠수원 */}
                        {/* {type === 'edit' && adminType==='superUser' && !player.isParticipated ? */}
                        {type === 'edit' && !player.isParticipated ?
                        <CheckBoxWrapper>
                            <Checkbox onChange={()=>{onCheckBoxChange(player.id)}}/>
                        </CheckBoxWrapper>
                        : null}
                    </ShortCol>
                </ColRow>
            ));
    };

    const TableLabel = () => (
        <ColLabelRow>
            <ShortCol>POS</ShortCol>
            <ShortCol>NO</ShortCol>
            <LongCol>NAME</LongCol>
            <ShortCol>QR 체크</ShortCol>
        </ColLabelRow>
    );

  return (
    homeStarting.length > 0 || awayStarting.length > 0 ?
    <div className='lineup'>
        {contextHolder}
        <TitleBox>
            <SectionTitle>출전 선수 명단</SectionTitle>
            { type === 'edit' ? 
            <Button onClick={refresh}>
                <FontAwesomeIcon icon={faArrowsRotate} style={{ marginRight:'6px' }}/>새로고침
            </Button>
            : null }
        </TitleBox>
        <Wrapper>
            <HalfBox>
                <HomeTeamBox>
                    <TeamName>{home}</TeamName>
                    <UniformBox>
                        <span>유니폼 상의 : {homeUniform?.top}</span>
                        <span>하의 : {homeUniform?.bottom}</span>
                        <span className='last'>스타킹 : {homeUniform?.stockings}</span>
                    </UniformBox>
                </HomeTeamBox>
                <Container>
                    <LineupBox>
                        <HomeLineupType>STARTING</HomeLineupType>
                        {TableLabel()}
                        {lineupSpitter(homeStarting)}
                        <HomeLineupType>SUBS</HomeLineupType>
                        {TableLabel()}
                        {lineupSpitter(homeSub)}
                    </LineupBox>
                    {type !== 'edit' ?
                    <InputBox>
                        <RecordScore 
                            lineupContent={lineupContent} 
                            homeStarting={homeStarting} awayStarting={awayStarting}
                            homeSub={homeSub} awaySub={awaySub}
                            matchId={matchId}
                            recordContent={recordContent} createRecord={createRecord}
                        />
                    </InputBox> 
                    : null}
                </Container>
                {type === 'edit' ? 
                <RecordScoreEdit
                    lineupContent={lineupContent} 
                    homeStarting={homeStarting} awayStarting={awayStarting}
                    homeSub={homeSub} awaySub={awaySub}
                    form={form} matchId={matchId}
                    recordContent={recordContent} createRecord={createRecord}
                    setCompareScoreHome={setCompareScoreHome}
                />
                : null}
            </HalfBox>

            <div><VerticalLine /></div>

            <HalfBox>
                <AwayTeamBox>
                    <TeamName>{away}</TeamName>
                    <UniformBox>
                        <span>유니폼 상의 : {awayUniform?.top}</span>
                        <span>하의 : {awayUniform?.bottom}</span>
                        <span className='last'>스타킹 : {awayUniform?.stockings}</span>
                    </UniformBox>
                </AwayTeamBox>
                <Container>
                    <LineupBox>
                        <AwayLineupType>STARTING</AwayLineupType>
                        {TableLabel()}
                        {lineupSpitter(awayStarting)}
                        <AwayLineupType>SUBS</AwayLineupType>
                        {TableLabel()}
                        {lineupSpitter(awaySub)}
                    </LineupBox>
                    {type !== 'edit' ?
                    <InputBox>
                        <RecordScoreAway 
                            homeStarting={homeStarting} awayStarting={awayStarting}
                            homeSub={homeSub} awaySub={awaySub}
                            matchId={matchId}
                            recordContent={recordContent} createRecord={createRecord}
                        />
                    </InputBox>
                    : null}
                </Container>
                {type === 'edit' ? 
                <RecordScoreAwayEdit
                    homeStarting={homeStarting} awayStarting={awayStarting}
                    homeSub={homeSub} awaySub={awaySub}
                    form={form} matchId={matchId}
                    recordContent={recordContent} createRecord={createRecord}
                    setCompareScoreAway={setCompareScoreAway}
                    setLoadingStatus={setLoadingStatus}
                />
                : null}
            </HalfBox>
        </Wrapper>
        <PlayerDetailModal 
            open={open} setOpen={setOpen} detail={selectedPlayer} 
            matchName={matchName} matchTime={matchTime} matchLocation={matchLocation}
            home={home} away={away}
        />

    </div> 
    : 
    <>
    {contextHolder}
        <TitleBox>
            <SectionTitle>출전 선수 명단</SectionTitle>
            <Button onClick={refresh}>
                <FontAwesomeIcon icon={faArrowsRotate} style={{ marginRight:'6px' }}/>새로고침
            </Button>
        </TitleBox>
        <NullWrapper>출전 선수 검인 전입니다</NullWrapper>
    </>
  )
}

export default Lineup

const Wrapper = styled.div`
display: flex;
/* gap: 37px; */
`;

const TitleBox = styled.div`
display: flex;
margin-top: 45px;
margin-bottom: 19px;
padding-right: 24px;
`;

const SectionTitle = styled.div`
color: #6A6A6A;
font-family: 'NanumSquareNeo800';
font-size: 16px; 
margin-right: auto;
`;

const NullWrapper = styled.div`
color: #B6B6B6;
font-family: 'NanumSquareNeo800';
font-size: 18px;
height: 398px;
display: flex;
align-items: center;
justify-content: center;
`;

const HalfBox = styled.div`
flex: 1;
`;

const Container = styled.div`
display: flex;
flex: 1;
`;

const LineupBox = styled.div`
flex: 6;
display: flex;
flex-direction: column;
font-size: 12px;
text-align: center;
margin-right: 14px;
`;

const VerticalLine = styled.div`
/* height: 200px; */
height: 100%;
width: 1px;
background-color: #E4E4E4;
margin-right: 18px;
margin-left: 18px;
`;

const ShortCol = styled.div`
display: flex;
flex: 1;
justify-content: center;
align-items: center;
border-right: 1px solid #F0F0F0;
color: ${props => props.color===true ? '#FF8667' 
: props.color===false ? '#498AFF'
: ''};
`;

const LongCol = styled.div`
display: flex;
flex: 2;
align-items: center;
justify-content: center; 
border-right: 1px solid #F0F0F0;
overflow-x: hidden;
span {
    color: black;
    text-decoration: underline;
    text-underline-offset: 4px;
    cursor: pointer;
}
`;

const InputBox = styled.div`
flex: 4;
`;

const Row = styled.div`
width: 100%;
height: 29px;
display: flex; 
`;

const ColLabelRow = styled(Row)`
font-family: 'NanumSquareNeo800';
color: #6A6A6A;  
border: 1px solid #F0F0F0;
border-right: none;
background-color: #FAFAFA;
`;

const ColRow = styled(Row)`
color: #303030;
border: 1px solid #F0F0F0;
border-right: none;
border-top: none;
overflow: hidden;
`;

const TeamBox = styled.div`
display: flex;
height: 37px;
border-radius: 6px;
align-items: center;
color: white;
`;

const HomeTeamBox = styled(TeamBox)`
background-color: #FF8667;
`;

const AwayTeamBox = styled(TeamBox)`
background-color: #498AFF;
`;

const LineupType = styled.div`
text-align: left;
font-family: 'NanumSquareNeo900';
font-size: 12px;
font-weight: 900;
margin-bottom: 16px;
margin-top: 20px;
`;

const HomeLineupType = styled(LineupType)`
color: #FF8667;
`;

const AwayLineupType = styled(LineupType)`
color: #498AFF;
`;

const TeamName = styled.div`
font-family: 'NanumSquareNeo800';
margin-left: 20px;
`;

const UniformBox = styled.div`
margin-left: auto;
span{
    margin-right: 13px;
}
.last {
    margin-right: 20px;
}
`;

const Captain = styled.span`
line-height: 12px;
margin-left: 17px; 
span{
    font-family: 'Chakra Petch Regular';
}
`;

const QrStatusWrapper = styled.div`
width: 100%;
`

const CheckBoxWrapper = styled.div`
padding-right: 10px;
`

