import React, { useEffect, useState } from 'react'
import { Button, Table, Tag, Tooltip,  } from 'antd';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { 지역필터 } from '../../common/util';
import useGetApplyTeam from '../../hooks/Comp/useGetApplyTeam';
import StyleAtoms from '../../common/StyleAtoms';
import images from '../../common/images';
import { styled } from 'styled-components'
import Navigation from '../../common/Navigation';
import { PhoneNumberFormatter } from '../../common/util';
import { useCookies } from 'react-cookie'
import { ACCESS_TOKEN } from '../../common/api';
import { adminTypeChecker } from '../../common/util';
import Message from '../../components/commons/Message';
import useUpdateTeamApplication from '../../hooks/Comp/useUpdateTeamApplication';

const TeamList = () => {
  const {PageTitle, SearchBox, SSearch, GreyText, BlueImg, InvertImg, 
    TitleBox, Title2, SumBox, SumCol, SumColBl, ButtonBox, SToolIcon } = StyleAtoms();
  const { Column } = Table;
  const { contextHolder, messageSuccess, messageError, messageLoading, closeMessageLoading } = Message();
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const { compId } = useParams();
  const location = useLocation();
  const { compName } = queryString.parse(location.search);
  const { teamList, getApplyTeam } = useGetApplyTeam();
  const {goComp, goCompList} = Navigation();
  const [cookies] = useCookies([ACCESS_TOKEN]);
  const adminType = adminTypeChecker(cookies);
  const { updateTeamApplication } = useUpdateTeamApplication();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);


  const rowClassName = (record, index) => {
    if (index % 2 !== 0) {
      // 짝수 로우 클래스
      return 'even-row';
    }
    return '';
  };

  const handleSearch = () => {
    const searchTextLower = searchText.toLowerCase();  
    const filtered = teamList.filter(item => item.name.toLowerCase().includes(searchTextLower));
    setFilteredData(filtered);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const onPageChange = (newPage, pageSize) => {
    setPage(newPage)
    setPageSize(pageSize)
    const query = queryString.stringify({ page: newPage });
    navigate({ search: `?compName=${encodeURIComponent(compName)}&${query}` });
  }

  const onUpdateTeamApplication = async (teamId) => {
    messageLoading();

    const updResult = await updateTeamApplication(teamId)
    if (updResult === 200) {
      closeMessageLoading();
      messageSuccess();
      getApplyTeam(compId);
    } else {
      closeMessageLoading();
      messageError();
    }
  }

  useEffect(()=>{
    getApplyTeam(compId);
  },[])

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.page) {
      setPage(Number(parsed.page));
    }
  }, [location.search]);

  return (
    <Container>
      {contextHolder}
      <PageTitle>
        <InvertImg src={images.icon.trophy} alt='' />
        <span onClick={goComp} className='pointer'>대회</span>
        <img src={images.icon.arrow} alt='' />
        <span className='pointer' onClick={()=>{goCompList(compId, compName)}}>{compName}</span>
        <BlueImg src={images.icon.arrow} alt='' />
        <span className='blue'>참가 팀 리스트</span>
      </PageTitle>
      <SearchBox>
        <SSearch
          placeholder="팀 이름을 검색해 보세요"
          onChange={handleSearchChange}
          onSearch={handleSearch}
          value={searchText}
        />
      </SearchBox>
      <Title2>참가 팀 리스트</Title2>

      <TitleBox>
        <SumBox>
          <SumColBl>
            <span>전체</span>  
            <span>{teamList?.length}</span>  
          </SumColBl>
          <SumCol>
            <span>승인</span>
            <span>{teamList?.filter(item => item.applyResult === 'ACCEPT').length}</span>  
          </SumCol>
          <SumCol>
            <span>대기</span>
            <span>{teamList?.filter(item => item.applyResult === 'WAIT').length}</span>  
          </SumCol>
          <SumCol>
            <span>거절</span>
            <span>{teamList?.filter(item => item.applyResult === 'REJECT').length}</span>  
          </SumCol>
        </SumBox>
        {/* 🧠수원 */}
        {/* {adminType==='superUser' ?
        <ButtonBox>
          <Link to={`/comp/${compId}/team/add?compName=${encodeURIComponent(compName)}`}>
            <Button type='primary'>참가 팀 추가</Button>
          </Link>
          <Link to={`/comp/${compId}/team/edit?compName=${encodeURIComponent(compName)}`}>
            <Button type='primary' danger>팀 리스트 수정</Button>
          </Link>
        </ButtonBox>
        : null} */}
        <ButtonBox>
          <Link to={`/comp/${compId}/team/add?compName=${encodeURIComponent(compName)}`}>
            <Button type='primary'>참가 팀 추가</Button>
          </Link>
          <Link to={`/comp/${compId}/team/edit?compName=${encodeURIComponent(compName)}`}>
            <Button type='primary' danger>팀 리스트 수정</Button>
          </Link>
        </ButtonBox>
      </TitleBox>
      
      <Table dataSource={filteredData || teamList} 
        pagination={
          {
            defaultPageSize: 8, 
            position: ['bottomRight'], 
            hideOnSinglePage: false, 
            showSizeChanger:true,
            current: page,
            onChange:(page, pageSize)=>{onPageChange(page, pageSize)}
          }
        }
        rowClassName={rowClassName}
        rowKey={(record)=>record.id}
      >
        <Column title="신청 순서" key="order"
          render={(text, record, index) =>
            (index + 1) + pageSize * (page - 1)
          }
          className='orderCol'
        />
        <Column title="팀 이름" dataIndex="name" key="name"/>
        <Column title="팀 지역" key="location" 
          filters={지역필터} onFilter={(value, record) => record.location.startsWith(value)} filterSearch={true} 
          render={(record)=>(
            <span>{record.location} {record.subLocation}</span>
        )}/>
        <Column title="신청자 연락처" key="represent" 
          render={(record)=>(
            <span>{record.represent.name}&nbsp;&nbsp;&nbsp;&nbsp;{PhoneNumberFormatter(record.represent.phone)}</span>
        )}/>
        
        <Column title={<span>인원 수 <GreyText>&#40;승인 인원수&#47;신청 인원수&#41;</GreyText></span>} 
          key="applyMemberCount"
          render={(record) => (
            <span>{record.applyMemberValidatedCount}/{record.applyMemberCount}</span>  
          )}
        />
        <Column title="팀 승인 여부" dataIndex="applyResult" key="applyResult" 
          render={(applyResult)=>(
            applyResult === 'ACCEPT' 
            ? <Tag color={'blue'} key={document}>승인</Tag>
            : applyResult === 'WAIT'  
            ? <Tag color={'gold'} key={document}>대기</Tag>
            : <Tag color={'red'} key={document}>거절</Tag>)} 
        />

        {/* 🧠수원 */}
        {/* {adminType==='superUser' ? 
        <Column
          title={<Tooltip title='대회 참가 선수 명단이 팀 전체 명단으로 업데이트 됩니다'>선수 명단 업데이트<SToolIcon/></Tooltip>}
          key="updatePlayer"
          render={(record) => (
            <Button onClick={()=>{onUpdateTeamApplication(record.id)}}>업데이트</Button>
          )}
        />
        : null} */}
        <Column
          title={<Tooltip title='대회 참가 선수 명단이 팀 전체 명단으로 업데이트 됩니다'>선수 명단 업데이트<SToolIcon/></Tooltip>}
          key="updatePlayer"
          render={(record) => (
            <Button onClick={()=>{onUpdateTeamApplication(record.id)}}>업데이트</Button>
          )}
        />
        
        <Column
          title="참가 선수 리스트"
          key="player"
          render={(record) => (
            <Link to={`/comp/${compId}/team/${record.id}/player?compName=${encodeURIComponent(compName)}&teamName=${encodeURIComponent(record.name)}`}>참가 선수 리스트</Link>
          )}
        />
      </Table>
    </Container>
  )
}

export default TeamList

const Container = styled.div`
.orderCol {
  max-width: 60px;
}
`


