import React, { useState } from 'react'
import { styled } from 'styled-components'
import images from '../common/images';
import EditAccountModal from '../components/Signin/EditAccountModal';
import { 가이드북관리자, 가이드북유저 } from '../common/Data';
import { Link } from 'react-router-dom';

const Main = () => {
  const [open, setOpen] = useState();

  const showModal = () => {
    setOpen(true);
  };

  console.log('Hello Admin !');

  return (
    <Wrapper>
      <Container>
        <MainContainer>
          <Img src={images.main_banner} alt=''/>
        </MainContainer>

        <GuideButtonContainer>
          <Link to={가이드북관리자} target="_blank" rel="noopener noreferrer" style={{marginTop:'auto'}}>
            <GuideBookButton1>관리자용 가이드북</GuideBookButton1>
          </Link>
          <Link to={가이드북유저} target="_blank" rel="noopener noreferrer" style={{marginTop:'auto'}}>
            <GuideBookButton2>유저용 가이드북</GuideBookButton2>
          </Link>
        </GuideButtonContainer>
        
        <EditButtonContainer>
          <EditButton onClick={showModal}>아이디 및 비밀번호 변경</EditButton>
        </EditButtonContainer>
      </Container>
      <EditAccountModal open={open} setOpen={setOpen} />
    </Wrapper>
  )
}

export default Main

const Wrapper = styled.div`
background-color: #FF8667;
margin: -36px !important;
background-size: cover;
background-repeat: no-repeat;
background-image: url(${images.background});
`;

const Container = styled.div`
position: relative;
display: flex;
`;

const Img = styled.img`
width: 30%;
`;

const MainContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
position: absolute;
left: 50%;
transform: translate(-50%);
margin-top: 35vh;
`;

const GuideButtonContainer = styled.div`
display: flex;
flex-direction: row;
gap: 12px;
margin-left: 34px;
height: 100vh;
padding-bottom: 32px;
`;

const EditButtonContainer = styled.div`
display: flex;
width: 100%;
justify-content: right;
`;

const SButton = styled.button`
border-radius: 20px;
background: #1F1F1F;
color: white;
width: 174px;
height: 40px;
border: none;
cursor: pointer;
font-family: 'NanumSquareNeo';
margin-top: auto;
font-size: 14px;
`

const GuideBookButton1 = styled(SButton)`
background-color: white;
color: #FF8667;
`

const GuideBookButton2 = styled(SButton)`
background-color: white;
color: #498AFF;
`

const EditButton = styled(SButton)`
margin-right: 34px;
margin-bottom: 32px;
`;