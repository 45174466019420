import React, { useEffect } from 'react'
import Notification from '../../../components/commons/Notification';
import StyleAtoms from '../../../common/StyleAtoms';
import images from '../../../common/images';
import Navigation from '../../../common/Navigation';
import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';
import { adminTypeChecker } from '../../../common/util';
import { useCookies } from 'react-cookie';
import { ACCESS_TOKEN } from '../../../common/api';
import MatchAllLineup from '../../../components/Match/SuperUser/MatchAllLineup';

const MatchLineupEdit = () => {
    const {PageTitle, BlueImg, TitleBox, Title, SubTitle, InvertImg, CompTitle, RedDot } = StyleAtoms();
    const { contextHolder, successNoti, errorNoti, loadingNoti, closeLoadingNoti } = Notification();
    const {goComp, goError, goCompList, goMatchEdit} = Navigation();
    const location = useLocation();
    const { compName, homeTeamName, awayTeamName } = queryString.parse(location.search);
    const { compId, matchId } = useParams();
    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);
    const adminType = adminTypeChecker(cookies);

    // 🧠수원
    // useEffect(()=>{
    //   if(adminType !== 'superUser') {
    //     goError();
    //   }
    // },[])

  return (
    <>
    {contextHolder}
    <PageTitle>
        <InvertImg src={images.icon.trophy} alt='' />
        <span onClick={goComp} className='pointer'>대회</span>
        <img src={images.icon.arrow} alt='' />
        <span className='pointer' onClick={()=>{goCompList(compId, compName)}}>{compName}</span>
        <img src={images.icon.arrow} alt='' />
        <span onClick={()=>{goMatchEdit(compId, matchId, compName)}} className='pointer'>경기 정보 수정</span>
        <BlueImg src={images.icon.arrow} alt='' />
        <span className='blue'>출전 선수 명단 관리</span>
    </PageTitle>
    <TitleBox>
        <Title>출전 선수 명단 관리</Title>
    </TitleBox>
    <MatchAllLineup homeTeamName={homeTeamName} awayTeamName={awayTeamName} />
    </>
  )
}

export default MatchLineupEdit